<template>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="8" md="6">
          <v-card class="pa-4">
            <!-- Hiển thị ảnh QR -->
            <!-- <input type="file" ref="fileInput" style="display: none;" @change="handleFileUpload" />
            <v-btn @click="openFileInput" outlined color="primary">
            <v-icon left>mdi-cloud-upload</v-icon>
            Chọn ảnh QR
            </v-btn>
            <div style="width: 100%;" class="mt-5"> 
                <img :src="bank?.image" v-if="bank.image" alt="Uploaded Image" width="350px" />
            </div> -->
  
            <!-- Form nhập thông tin -->
            <div>
                <v-text-field v-model="bank.name_bank" label="Tên ngân hàng"></v-text-field>
              <v-text-field v-model="bank.number_bank" label="Số ngân hàng"></v-text-field>
              <v-text-field v-model="bank.user_name_bank" label="Tên chủ thẻ"></v-text-field>
              <v-btn color="primary" @click="createBank" v-if="!id">Thêm</v-btn>
              <v-btn color="primary" @click="updateBank" v-else>Sửa thông tin</v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import network from '@/core/network'
  import axios from 'axios'
  export default {
    data() {
      return {
        id: '',
        bank: {
            name_bank: '',
            user_name_bank: '',
            number_bank: '',
            image: '',
        },
        imageUrl: ''
      };
    },
    mounted() {
        this.getBankConfig();
    },
    methods: {
        getBankConfig() {
            network.get('/configs/get-bank-config').then(res => {
                console.log(res.data);
                if (res.data.length > 0) {
                    this.bank = res.data[0];
                    this.imageUrl = res.data[0].image;
                    this.id = res.data[0]._id;
                }
            })
        },

      async readFileAsBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = async () => {
          const image = new Image();
          image.src = reader.result;

          image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Define the new dimensions for the resized image
            const maxWidth = 200; // Adjust as needed
            const maxHeight = 200; // Adjust as needed
            let width = image.width;
            let height = image.height;

            if (width > height) {
              if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
              }
            } else {
              if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
              }
            }

            canvas.width = width;
            canvas.height = height;

            // Draw the image onto the canvas
            ctx.drawImage(image, 0, 0, width, height);

            // Convert the canvas content to a data URL
            const resizedDataURL = canvas.toDataURL('image/jpeg');

            // Resolve the promise with the resized data URL
            resolve(resizedDataURL);
          };
        };

        reader.onerror = error => {
          reject(error);
        };
      });
    },

    async handleFileUpload(event) {
        const file = event.target.files[0];

      if (file) {
        this.file = file;
        const uriImg = this.convertBase64ToBinary(await this.readFileAsBase64(file));

        const formData = new FormData();
        formData.append('file', uriImg);

        await axios.post(
          network.URI('/upload'),
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                'access_token'
              )}`,
            }
          }
        ).then(res => {
          this.bank.image = res.data.secure_url;
          console.log(this.bank.image);
        }).catch(err => {
          console.log(err);
        })
      }
    },

    openFileInput() {
      this.$refs.fileInput.click();
    },

    createBank() {
        if (this.bank.name_bank == '' || this.bank.user_name_bank == '' || this.bank.number_bank == '') {
            this.$toast.open({
          message: 'Chưa đủ thông tin',
          type: 'error',
          position: 'top'

        });        
        return false;

    } 
        network.post('/configs/create-bank', this.bank).then(res => {
            console.log(res);
            this.$toast.open({
          message: res.data.message,
          type: 'success',
          position: 'top'
        });
        }).catch(err => {
            this.$toast.open({
          message: err.response.data.message,
          type: 'error',
          position: 'top'
        });
        });
    },

    updateBank() {
        if (this.bank.name_bank == '' || this.bank.user_name_bank == '' || this.bank.number_bank == '') {
            this.$toast.open({
          message: 'Chưa đủ thông tin',
          type: 'error',
          position: 'top'

        });        
        return false;

    } 
        network.post('/configs/update-bank-config', {
            id: this.id,
            bank: this.bank
        }).then(res => {
            this.$toast.open({
          message: res.data.message,
          type: 'success',
          position: 'top'
        });
        this.getBankConfig();
        }).catch(err => {
            this.$toast.open({
          message: err.response.data.message,
          type: 'error',
          position: 'top'
        });
        });
    },

    convertBase64ToBinary(dataURI) {
      var byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
      else byteString = unescape(dataURI.split(',')[1]);

      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], { type: mimeString });
    },
    }
  };
  </script>
  
  <style>
  /* Thêm CSS tùy chỉnh nếu cần */
  </style>
  